@import "var";
@import "loader";
@import "eventPlaceholder";
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
    height: 100lvh;
}
body{
  background: white;
  // height: 100lvh;
  overflow: scroll;
  //background: var(--tg-theme-bg-color);
  //color: var(--tg-theme-text-color);
}
.my-header{
  background: white;
  padding: 10px;
  // border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
}
.my-header-city{
  font-size: 1.5rem;
  font-weight: bold;
}
.my-card-title{
  font-weight: bold;
  font-size: 1.2rem;
}
.my-logo{
  color: $main-active-color;
  font-size: 1.3rem;
  text-decoration: none;
}
.my-logo-text{
  font-weight: bold;
}
.my-logo-text-first{
  color: $main-active-color;
}
.my-logo-text-second{
  color: black;
}
.my-card-date{
  font-size: 0.8rem;
}
.my-time-panel-button{
  border: 1px solid #ececec;
  font-weight: bold;
  border-radius: 8px;
  background: $main-passive-color;
  color: white;
  padding: 0.1rem 0.5rem;
}

// .my-time-panel-button-days{
//   font-size: 1.25rem;
// }
// .my-time-panel-button-tag {
//   font-size: 1.12rem;
// }
.my-time-panel-button:hover{
  cursor: pointer;
}
.my-time-panel-button-active{
  background: $main-active-color;
}
.card-button-panel-need-reg-button{
  border: 1px solid #1c1c1c;
  padding: 3px;
  font-size: 0.6rem;
  background: $tag-bg-color;
  font-weight: bold;
  border-radius: 10px;
  color: $main-active-text-color;
}
.mobile-nav {
  background: #F1F1F1;
  position: fixed;
  bottom: 0;
  height: $mobiel-nav-hieight;
  width: 100%;
  display: flex;
  justify-content: space-around;
  right: 1px;
}
.mobile-nav-margin{
    height: $mobiel-nav-hieight;
    visibility: hidden;
}
.bloc-icon{
  text-decoration: none;
  margin-top: 0.5rem;
}
.bloc-icon-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.block-icon-label{
  color: $main-active-color;
}
.bloc-icon-img svg{
  height: 2rem;
  width: 2rem;
  color: $main-active-color;
}
.create-event-form{
  //background: $main-passive-color;
  border: 3px solid $main-active-color;
  padding: 20px;
  border-radius: 20px;
  color: $main-light-text-color;
  font-weight: bold;
  min-height: 60vh;
}
.my-event-button{
  background: $main-active-color;
  color: $main-active-text-color;
  font-weight: bold;
  padding: 5px;
}
.my-custom-tab{
  position: absolute;
  height: 60vh;
  left: 0px;
}
.my-invisible{
  left:999px;
  //width: 1px;
  //height: 1px;
}
.event-draw-view{
  height: 30px;
  width: 5px;
  position: absolute;
}
.my-event-form{
  min-height: 60vh;
  overflow-y: scroll;
  border: 2px solid $main-active-color;
  padding: 8px;
}
.list-of-tags{
  //height: 40px;
  max-width: 90%;
  overflow-y: scroll;
  display: flex;
}
.single-tag{
  //border: 1px solid #ececec;
  //font-weight: bold;
  //border-radius: 8px;
  //background: $main-passive-color;
  //color: white;
  //padding: 0.1rem 0.5rem;
  //height: 100%;
  //white-space: nowrap;

  height: 100%;
  white-space: nowrap;
}
.text-button{
  padding:5px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  text-align: "center"
}
.text-button-show-body{
    border: 1px solid $main-active-color;
      color: $main-active-color;

}
.text-button-show-short {
  border: 1px solid #36078A;
    color: #36078A;
}
.scrollable-element {
  overflow-y: scroll;
  height: 32rem;
  font-size: 6.25rem;
  border: 1px solid;
}
.full-list-of-tags{
  display: flex;
  flex-wrap:wrap;
}
.full-list-of-tags-item{
  display: flex;
// display: inline-block;
  margin-right: 0.3rem;
  // padding: 3px;
  // padding-right: 3px;
  // padding-left: 3px;
  border: 1px solid $main-active-color;
  border-radius: 9px;
  // padding-right: 5px;
  // padding-left: 5px;
  margin-bottom: 0.4rem;
  color: $main-active-color;
}
.full-list-of-tags-item-text{
  padding: 3px;
    padding-right: 3px;
    padding-left: 3px;
    padding-right: 5px;
      padding-left: 5px;
      // font-weight: bold;
}
.full-list-of-tags-item-selected{
  background: $main-active-color;
  color: white;
}